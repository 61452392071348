import React, { useCallback, useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import { currentProject } from '../../Global/currentProject';
import AppLanguages, { languageDataInterface } from '../../lngProvider';
import Translator from '../../services/translator';
import initTranslations from '../../services/initTranslations';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import LanguageItem from '../LanguageSwitcher/LanguageItem';
import { useSelector } from 'react-redux';
import { storeType } from '../../index';
import UserSelfModel from '../../Models/UserSelfModel';
import { MainButton, MainTextField } from '@emisys/audience-sdk-ui-react';
import CloseIcon from '@mui/icons-material/Close';
import { NotificationManager } from '../Notification/NotificationManager';
import './index.css';

interface HeaderInterface {
  currentLocale: string;
  setCurrentLocale: (locale: string) => void;
  onToggleCollapsedNav: () => void;
}

const Header = (props: HeaderInterface) => {
  const [anchorLang, setAnchorLang] = useState<null | HTMLElement>(null);
  const [anchorHelp, setAnchorHelp] = useState<null | HTMLElement>(null);
  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false);
  const [subjectMessage, setSubjectMessage] = useState('');
  const [message, setMessage] = useState('');
  const [isSendMessageLoading, setIsSendMessageLoading] = useState(false);

  const user: UserSelfModel = useSelector(
    (state: storeType) => state.user.userSelfDetails.details
  );

  const nameProject = currentProject?.name;

  const handleSwitchLanguage = useCallback(
    (lang: languageDataInterface) => {
      initTranslations(lang.locale);
      props.setCurrentLocale(lang.locale);
      handleClose();
    },
    [props]
  );

  const onLangSwitcherSelect = (event: any) => {
    setAnchorLang(event.currentTarget);
  };

  const handleClickHelpIcon = (event: any) => {
    setAnchorHelp(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorHelp(null);
    setAnchorLang(null);
  };

  const handleSendMessage = useCallback(() => {
    setIsSendMessageLoading(true);
    fetch(
      'https://hooks.slack.com/services/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',
      {
        method: 'POST',
        body: JSON.stringify({
          blocks: [
            {
              type: 'header',
              text: {
                type: 'plain_text',
                text: 'ℹ️ DEMANDE DE SUPPORT ℹ️',
                emoji: true,
              },
            },
            {
              type: 'section',
              fields: [
                {
                  type: 'mrkdwn',
                  text:
                    '🎟️ *Projet :*\n<https://live.byemisys.com/admin/' +
                    currentProject.id +
                    '|' +
                    currentProject.name +
                    ' #' +
                    currentProject.id +
                    '>',
                },
                {
                  type: 'mrkdwn',
                  text:
                    '👤 *Client :*\n<https://audience.byemisys.com/admin/' +
                    currentProject.id +
                    '/user/edit?id=' +
                    user.id +
                    '|' +
                    user.email +
                    ' #' +
                    user.id +
                    '>',
                },
              ],
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: '*Sujet :* ' + subjectMessage,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: message,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: '<mailto:' + user.email + '?subject=Test|✉️ Répondre>',
              },
            },
            {
              type: 'divider',
            },
          ],
        }),
      }
    )
      .then(() => {
        setSubjectMessage('');
        setMessage('');
        setIsSendMessageModalOpen(false);
        NotificationManager.success(
          Translator.trans('appModule.message.send.success')
        );
      })
      .catch(() => {
        NotificationManager.error(
          Translator.trans('appModule.message.send.failure')
        );
      })
      .finally(() => {
        setIsSendMessageLoading(false);
      });
  }, [message, subjectMessage, user?.email, user?.id]);

  const handleOpenModal = () => {
    setIsSendMessageModalOpen(true);
    handleClose();
  };

  return (
    <>
      <AppBar className={'app-main-header'}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          <IconButton
            className={`jr-menu-icon mr-3 d-block d-md-none`}
            aria-label="Menu"
            onClick={props.onToggleCollapsedNav}
          >
            <span className="menu-icon color-menu" />
          </IconButton>

          <h1>{nameProject}</h1>

          <ul className="header-notifications list-inline ml-auto full-height">
            <li className={'list-inline-item d-flex pointer'}>
              <HelpOutlineIcon
                className={'icon-help-header'}
                onClick={handleClickHelpIcon}
                aria-controls="help-menu"
                aria-haspopup="true"
              />
              <Menu
                id="help-menu"
                anchorEl={anchorHelp}
                open={Boolean(anchorHelp)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <HelpOutlineIcon className={'icon-help-menu'} />
                  {Translator.trans('ticketing.header.help.online')}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ImportContactsIcon className={'icon-help-menu'} />
                  {Translator.trans('ticketing.header.help.guide')}
                </MenuItem>
                <MenuItem onClick={handleOpenModal}>
                  <ChatBubbleOutlineIcon
                    className={'icon-help-menu'}
                    onClick={handleSendMessage}
                  />
                  {Translator.trans('ticketing.header.help.chat')}
                </MenuItem>
              </Menu>
            </li>
            <li className="list-inline-item languages pointer">
              <div
                className="d-flex align-items-center pt-1"
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={onLangSwitcherSelect}
              >
                <i
                  className={`flag flag-24 flag-${currentProject.language.icon}`}
                />
              </div>
              <Menu
                id="language-menu"
                anchorEl={anchorLang}
                open={Boolean(anchorLang)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {AppLanguages.map((language: languageDataInterface) => (
                  <MenuItem
                    key={language.locale}
                    onClick={() => handleSwitchLanguage(language)}
                  >
                    <LanguageItem language={language} />
                  </MenuItem>
                ))}
              </Menu>
            </li>
          </ul>
        </Toolbar>
      </AppBar>
      {isSendMessageModalOpen && (
        <div className={'feedback-container'}>
          <div className={'feedback-header-container'}>
            <div className={'title-feedback'}>
              {Translator.trans('appModule.contactUs')}
            </div>
            <div
              className={'close-feedback'}
              onClick={() => setIsSendMessageModalOpen(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className={'feedback-form-container'}>
            <div className={'feedback-form-label'}>
              {Translator.trans('appModule.subject')}
            </div>
            <div className={'feedback-form-input'}>
              <MainTextField
                onChange={(event) => setSubjectMessage(event.target.value)}
                value={subjectMessage}
                maxLength={100}
              />
            </div>
            <div className={'feedback-form-label'}>
              {Translator.trans('appModule.message')}
            </div>
            <div className={'feedback-form-input'}>
              <MainTextField
                className={'feedback-form-message'}
                multiline
                minRows={10}
                maxRows={10}
                maxLength={2000}
                onChange={(event) => setMessage(event.target.value)}
                value={message}
              />
            </div>
            <div className={'feedback-form-send-btn'}>
              <MainButton
                buttonType={'contain'}
                loading={isSendMessageLoading}
                onClick={handleSendMessage}
              >
                {Translator.trans('appModule.send')}
              </MainButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
