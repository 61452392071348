import MailsContentModel from './MailsContentModel';
import ContentContentModel from './ContentContentModel';

export default class ContentModel {
  mails: MailsContentModel[];
  contents: ContentContentModel[];

  constructor(data: any) {
    this.mails = data.mails.map((mail: any) => new MailsContentModel(mail));
    this.contents = data.contents.map(
      (content: any) => new ContentContentModel(content)
    );
  }
}
