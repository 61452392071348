import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getVatRatesFailure, getVatRatesSuccess } from '../actions';
import { vatRatesConstants } from '../constants';
import { getVatRatesApi } from '../api';
import { currentProject } from "../../Global/currentProject";

export function* fetchGetVatRates(action: any): any {
  try {
    const vatRates = yield call(getVatRatesApi, {
      args: {
        countryCode: action.countryCode === 'all' ? null : action.countryCode,
        order: 'rate',
        locale: currentProject.language.locale,
      },
    });
    yield put(getVatRatesSuccess(vatRates));
  } catch (error) {
    yield put(getVatRatesFailure(error));
  }
}

export function* getVatRates() {
  yield takeLatest(vatRatesConstants.VAT_RATES_GET_REQUEST, fetchGetVatRates);
}

export default function* vatRatesSaga() {
  yield all([fork(getVatRates)]);
}
